@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
}

.header {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #07074d;
}

.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 570px;
  width: 100%;
  padding: 40px;
}

.formbold-mb-3 {
  margin-bottom: 15px;
}

.formbold-form-label {
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.formbold-form-input {
  text-align: center;
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}

.formbold-form-input:focus {
  border-color: #04497a;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-input-flex {
  display: flex;
  gap: 20px;
}

.formbold-btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #04497a;
  cursor: pointer;
  margin-top: 25px;
}

.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}