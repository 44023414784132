.container {
  height: 90vh;
  display: grid;
  /* border: 2px solid blue; */
  place-items: center;
}

.slider {
  width: 80vw;
  /* max-width: 900px; */
  height: 82vh;
  position: relative;
  /* border: 2px solid red; */
  overflow: hidden;
  /* <=== */
  border-radius: 15px;
}

.slide {
  width: 80vw;
  /* max-width: 850px; */
  height: 82vh;
  position: absolute;
  transition: all 0.9s ease-in-out;

}

.slide img {
  width: 80vw;
  height: 100%;
  object-fit: cover;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  /* background-color: black; */
  color: #04497a;
  padding: 8px;
  text-align: center;
  font-size: 25px;
  font-weight: bolder;
}

.date {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 16px;
}

.btn {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  z-index: 10px;
  cursor: pointer;
  background-color: #fff;
  font-size: 18px;
  bottom: -200px;
}

.btn:active {
  transform: scale(1.1);
}

.btn-prev {
  top: 45%;
  left: 2%;
}

.btn-next {
  top: 45%;
  right: 2%;
}

.icon-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  color: #04497a;
  cursor: pointer;
}