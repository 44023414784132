@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #f5f7fb;
  color: #2d3748;
}

.header {
  font-weight: 700;
  font-size: 24px;
  color: #04497a;
  margin-bottom: 25px;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 15px;
}

.main-wrapper {
  width: 100%;
  min-height: calc(100vh - 60px); /* Account for header/nav */
  padding: 20px 40px;
}

.form-wrapper {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}

/* Main Grid Layout */
.form-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  gap: 30px;
}

/* Left Panel */
.left-panel {
  background: #f8fafc;
  padding: 20px;
  border-radius: 8px;
  height: fit-content;
}

/* Employee Selection Area */
.employee-selection {
  margin-bottom: 25px;
}

.employee-info {
  padding: 15px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.info-field {
  margin-bottom: 15px;
}

.info-field:last-child {
  margin-bottom: 0;
}

.info-label {
  font-size: 13px;
  color: #64748b;
  margin-bottom: 5px;
}

.info-value {
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
  padding: 8px 12px;
  background: #f1f5f9;
  border-radius: 6px;
}

/* Right Panel */
.right-panel {
  padding: 20px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

/* Date Selection Grid */
.date-selection-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 25px;
}

/* Form Controls */
.form-control {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s;
}

.form-input:focus {
  border-color: #04497a;
  box-shadow: 0 0 0 2px rgba(4, 73, 122, 0.1);
}

/* File Upload */
.file-upload {
  margin-bottom: 25px;
}

.file-upload input {
  width: 100%;
  padding: 12px;
  background: #f8fafc;
  border: 2px dashed #cbd5e0;
  border-radius: 6px;
  cursor: pointer;
}

/* Button */
.submit-button {
  background: #04497a;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  width: auto;
  min-width: 150px;
}

.submit-button:hover {
  background: #03365c;
  transform: translateY(-1px);
}

/* Material UI Overrides */
.MuiFormControl-root {
  width: 100% !important;
}

.MuiSelect-select {
  padding: 10px 14px !important;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
  
  .left-panel {
    padding: 15px;
  }
  
  .right-panel {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .main-wrapper {
    padding: 15px;
  }
  
  .date-selection-grid {
    grid-template-columns: 1fr;
  }
}