.spinner {
  width: 100px;
  height: 100px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-right-color: #04497a;
  animation: spinner-a4dj62 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
  animation: spinner-a4dj62 2s infinite;
}

.spinner::before {
  border-right-color: #8cc540;
}

.spinner::after {
  margin: 8.9px;
  animation-duration: 3s;
}

@keyframes spinner-a4dj62 {
  100% {
    transform: rotate(1turn);
  }
}