/* body {
  background-color: #252a34;
  align-content: center;
  align-items: center;
} */

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url("../../assets/images/66230.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login_form_container {
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 40px 20px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  border-radius: 11px;
  font-family: "Inter", sans-serif;
  color: white;
  font-size: 14px;
}


.login_logo_container {
  text-align: center;
  /* width: 100%; */
}

.logo_style {
  width: 40%;
  max-width: 150px;
}

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  text-align: center;
}

.subtitle {
  font-size: 0.875rem;
  max-width: 80%;
  text-align: center;
  line-height: 1.2;
  color: black;
}

.input_container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* .icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
} */

.input_label {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.input_field {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  font-size: 14px;
}

.otp_input_field {
  width: 50%;
  height: 40px;
  padding: 0 10px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  text-align: center; /* Center the text inside the input */
  font-size: 16px;
  margin: auto;
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.sign-in_btn {
  width: 100%;
  height: 40px;
  border: 0;
  background: #04497a;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}

.sign-in_ggl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #04497a;
  border-radius: 7px;
  outline: none;
  color: white;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
  text-align: center;
  font-weight: bolder;
}

.sign-in_apl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #212121;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
}

.timer_container {
  margin-top: 10px;
  font-size: 0.9rem;
  color: black;
  text-align: center;
}

.sign-in_btn:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

@media screen and (max-width: 480px) {
  .login_form_container {
    width: 95%;
    padding: 30px 15px;
  }

  .title {
    font-size: 1.1rem;
  }

  .subtitle {
    font-size: 0.8rem;
  }

  .input_field, .sign-in_btn {
    height: 35px;
    font-size: 14px;
  }
}


.sign-in_btn:disabled {
  background: #6c757d;
  cursor: not-allowed;
}