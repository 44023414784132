@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
}

.formbold-mb-3 {
  margin-bottom: 15px;
}

.main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  width: 100%; /* Add this */
  flex: 1; /* Add this */
}

.form-wrapper {
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.formbold-form-title {
  text-align: center;
  margin-bottom: 40px;
}

.formbold-form-title h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #04497a;
}

/* Form Sections */
.form-section {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 25px;
  border: 1px solid #dde3ec;
}

.professional {
  color: #04497a;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde3ec;
}
.form-section h4 {
  color: #04497a;
  font-size: 16px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde3ec;
}

/* Grid Layouts */
.grid-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.grid-3-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

/* Form Elements */
.formbold-form-input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
  transition: border-color 0.3s ease;
}

.formbold-form-input:focus {
  border-color: #04497a;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-form-label {
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #07074d;
}

/* Radio Buttons */
.formbold-radio-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
}

.formbold-radio-label {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #dde3ec;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.formbold-radio-label:hover {
  background: #f8f9fa;
}

.formbold-input-radio:checked + .formbold-radio-label {
  background: #04497a;
  color: white;
  border-color: #04497a;
}

/* Submit Button */
.formbold-btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #04497a;
  color: white;
  cursor: pointer;
  margin-top: 25px;
  transition: all 0.3s ease;
  min-width: 200px;
}

.formbold-btn:hover {
  background-color: #033c65;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}

/* Error Messages */
.error-message {
  color: #cc0000;
  font-size: 12px;
  margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .formbold-form-wrapper {
    padding: 20px;
  }

  .grid-2-cols,
  .grid-3-cols {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .formbold-radio-flex {
    flex-direction: column;
  }

  .form-section {
    padding: 15px;
  }
}

/* File Input Styling */
input[type="file"].formbold-form-input {
  padding: 10px;
}

/* Select Styling */
select.formbold-form-input {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23536387' viewBox='0 0 16 16'%3E%3Cpath d='M8 12L2 6h12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  padding-right: 40px;
}

/* Helper Text */
.helper-text {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
  font-style: italic;
}

.role-radio-label:hover:not(:disabled) {
  background-color: #f8f9fa;
  border-color: #04497a;
}

.role-radio-label.selected {
  background-color: #04497a;
  border-color: #04497a;
  color: white;
}

.role-radio-label input[type="radio"] {
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: inherit;
}

.role-radio-label:focus-within {
  box-shadow: 0 0 0 2px rgba(4, 73, 122, 0.2);
}