.page-container {
  min-height: 100vh;
  background: #f5f7fb;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrapper {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  width: 70%;
  max-width: 600px;
  padding: 32px;
}

.header-section {
  margin-bottom: 28px;
}

.page-title {
  font-size: 24px;
  font-weight: 600;
  color: #04497a;
  margin-bottom: 16px;
}

.leave-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.employee-id {
  font-size: 15px;
  color: #4a5568;
  font-weight: 500;
}

.leave-balance {
  display: flex;
  gap: 24px;
}

.balance-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
}

.balance-item label {
  color: #64748b;
}

.balance-item span {
  font-weight: 600;
  color: #04497a;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-row {
  display: flex;
  gap: 24px;
}

.input-group {
  flex: 1;
}

.input-group label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 8px;
}

.required {
  color: #e53e3e;
  margin-left: 4px;
}

.form-input {
  width: 100%;
  padding: 10px 14px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  font-size: 15px;
  color: #2d3748;
  transition: all 0.2s ease;
}

.form-input:focus {
  border-color: #04497a;
  box-shadow: 0 0 0 2px rgba(4, 73, 122, 0.1);
  outline: none;
}

select.form-input {
  background-image: url("data:image/svg+xml,...");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  appearance: none;
}

textarea.form-input {
  resize: vertical;
  min-height: 100px;
}

.button-section {
  margin-top: 8px;
}

.submit-button {
  background: #04497a;
  color: white;
  padding: 12px 28px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover {
  background: #03365c;
  transform: translateY(-1px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-wrapper {
    padding: 24px;
  }

  .leave-info {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .form-row {
    flex-direction: column;
    gap: 16px;
  }

  .submit-button {
    width: 100%;
  }
}